import React, { useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import ENV from './utils/env'
import links from './utils/links'
import reportWebVitals from './reportWebVitals'
import { AppContextProvider, AppContext } from './context'

import './styles/variables.scss'
import './styles/themes.scss'
import './styles/common.scss'
import './styles/app.scss'
import './styles/form.scss'
import './styles/utils.scss'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import logging from './utils/logging'

import HomePage from './pages/home'
import RSVPPage from './pages/rsvp'
import NotFoundPage from './pages/notfound'

function App () {
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div>
      <Routes>
        <Route path='' element={<HomePage />} />
        <Route path='/rsvp/:uid' element={<RSVPPage />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </div>
  )
}

function AppWrapper () {
  return (
    <Router basename={links.getBaseName()}>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </Router>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(logging.debug)
