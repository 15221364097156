import React, { useContext, useEffect, useRef, useState } from 'react'
import browser from '../../utils/browser'
import http from '../../utils/http'
import links from '../../utils/links'
import './style.scss'
import { AppContext } from '../../context'
import { useNavigate } from 'react-router'

import GoogleMapReact from 'google-map-react'
import { GOOGLE_MAPS_API_KEY, DARKER_MAP_STYLES } from '../../constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const VenueMarker = ({ key, place }) => (
  <div key={key} className='mapview-venue-marker fas fa-map-marker-alt'></div>
)

// eslint-disable-next-line
export default () => {
  // eslint-disable-next-line
  const [appstate, appdispatch] = useContext(AppContext)
  const [scenarios, setScenarios] = useState([])
  const [headingItems, setHeadingItems] = useState([])
  const navigate = useNavigate()
  const inputRSVPEmail = useRef()
  const handleRSVPSubmit = async e => {
    if (e) e.preventDefault()
    let response = await http.call({
      path: '/rsvp',
      method: 'POST',
      form: {
        email: inputRSVPEmail.current.value
      }
    })
    if (response.ok) {
      navigate('/rsvp/' + response.data.uid)
    } else {
      window.alert(response.data.message)
    }
  }
  useEffect(() => {
    browser.setTitle()
  }, [])
  return (
    <div className='home'>
      <div
        className='hero'
        style={
          {
            // backgroundImage: "url(" + links.getPublicURL('/img/couple1.jpeg') + ""
          }
        }
      >
        <div className='container'>
          <img
            alt='wedding couple at wedding in South Africa'
            className='hero-preview'
            src={links.getPublicURL('/img/couple1.jpeg')}
          />
          <div className='gradient-bg'></div>
          <div className='heart-wrapper'>
            <img
              alt='logo of the website with heart'
              src={links.getPublicURL('/img/logo.png')}
              className='header-image'
            />
          </div>
        </div>
      </div>
      <div className='clearfix'></div>

      {/* Live stream block */}
      <div className='home-section'>
        <div className='container'>
          <p className='content-heading'>Live Stream Details</p>
          <iframe
            style={{
              margin: '0 auto',
              display: 'table'
            }}
            width='560'
            height='315'
            src='https://www.youtube.com/embed/MrccXw9zgYw'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div className='clearfix'></div>

      {/* RSVP block */}
      <div className='container'>
        <div className='split-box'>
          <div className='left-box'>
            <p className='content-heading rsvp-heading'>
              Welcome to our big day
            </p>
            <p className='content-sub-heading box-text'>
              Wedding is happening soon, watch here -{' '}
              <a href='https://youtu.be/MrccXw9zgYw'>youtu.be/MrccXw9zgYw</a>
            </p>
          </div>
          <div className='right-box'>
            <div
              className='right-box-img'
              style={{
                backgroundImage:
                  'url(' + links.getPublicURL('/img/red-rsvp.jpeg') + ''
              }}
            ></div>
          </div>
          <div className='clearfix'></div>
        </div>
        <div className='clearfix'></div>
      </div>
      <div className='clearfix'></div>

      {/* Location block */}
      <div
        className='home-section'
        style={{
          marginTop: '100px'
        }}
      >
        <div className='container'>
          <p className='content-heading'>Where is the party at?</p>
          <p className='content-sub-heading'>
            Beautiful city in Finland - Ei kysyvä tieltä eksy
          </p>
          <div className='mapbox'>
            <div className='mapview'>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: GOOGLE_MAPS_API_KEY
                }}
                defaultCenter={{
                  lat: 60.1613093,
                  lng: 24.9631168
                }}
                options={{
                  panControl: false,
                  mapTypeControl: false,
                  disableDefaultUI: true,
                  disableZoomControl: true,
                  styles: DARKER_MAP_STYLES,
                  minZoom: 11,
                  maxZoom: 11,
                  backgroundColor: 'none'
                }}
                defaultZoom={11}
                draggable={true}
                onClick={() => {}}
              >
                <VenueMarker lat={60.162188} lng={24.9639293} />
              </GoogleMapReact>

              <div className='clearfix'></div>
            </div>
            <div className='location-box'>
              <p className='title'>Valkosaari Telakka</p>
              <a
                className='meta-data'
                target='_blank'
                href='https://goo.gl/maps/cW6eHByhJT4Scg5BA'
                alt='event-location-link'
                rel='noreferrer'
              >
                Valkosaari, 00140 Helsinki, Finland
              </a>
              <p>* Pre-arranged ferries will take you to the location</p>
              <p>Date - 31st July 2022</p>
              <p>Time - 14:00 (ceremony starts at 15:00) - 22:00</p>
            </div>
            <div className='clearfix'></div>
          </div>
          <div className='venue-gallery-items'>
            <div className='venue-gallery-item'>
              <img
                alt='Wedding Venue Valkosaari Telakka'
                src={links.getPublicURL('/img/Valkosaari_1.jpg')}
              />
            </div>
            <div className='venue-gallery-item'>
              <img
                alt='Wedding Venue Valkosaari Telakka'
                src={links.getPublicURL('/img/Valkosaari_3.jpg')}
              />
            </div>
            <div className='venue-gallery-item'>
              <img
                alt='Wedding Venue Valkosaari Telakka'
                src={links.getPublicURL('/img/Valkosaari_4.jpg')}
              />
            </div>
            <div className='venue-gallery-item'>
              <img
                alt='Wedding Venue Valkosaari Telakka'
                src={links.getPublicURL('/img/Valkosaari_5.jpg')}
              />
            </div>
            <div className='clearfix'></div>
          </div>
        </div>
      </div>
      <div className='clearfix'></div>

      {/* Schedule block */}
      <div className='home-section'>
        <div className='container'>
          <p className='content-heading blue-theme'>Schedule for events</p>
          <p className='section-subheading blue-theme'>
            We have 2 events, the Mehendi Day and Main Wedding Day; check the
            schedule here
          </p>
          {/* Mehendi block */}
          <div className='split-box blue-theme'>
            <div className='left-box'>
              <div
                className='left-box-img'
                style={{
                  backgroundImage:
                    'url(' + links.getPublicURL('/img/indian-food.jpg') + '',
                  height: '200px',
                  width: '200px'
                }}
              ></div>
              <div
                className='left-box-img'
                style={{
                  backgroundImage:
                    'url(' + links.getPublicURL('/img/mehendi_1.jpeg') + '',
                  height: '200px',
                  width: '400px'
                }}
              ></div>
              <div
                className='left-box-img'
                style={{
                  backgroundImage:
                    'url(' + links.getPublicURL('/img/mehendi-music.jpg') + '',
                  height: '200px',
                  width: '200px'
                }}
              ></div>
              <div
                className='left-box-img'
                style={{
                  backgroundImage:
                    'url(' + links.getPublicURL('/img/aggretsuko.jpg') + '',
                  height: '200px',
                  width: '300px'
                }}
              ></div>
            </div>
            <div className='right-box right-box-mehendi'>
              <p className='content-heading blue-theme'>Mehendi Day</p>
              <a
                href='https://goo.gl/maps/LF2U44gFEjRwEiWN9'
                className='content-sub-heading blue-theme'
              >
                Location: Greija 00520 Helsinki
              </a>
              <p className='content-sub-heading blue-theme'>
                Date: 29th July 2022
              </p>
              <p className='content-sub-heading blue-theme'>
                Time: 17:00 - 22:00
              </p>
              <p className='content-sub-heading blue-theme details'>
                On Mehendi day you get beautiful Mehendi designs drawn by
                Artists on your hand. We will have Indian, South African &
                Finnsh snacks & drinks to enjoy on the side of Karoake & games.{' '}
              </p>
            </div>
          </div>
          {/* Wedding Ceremony block */}
          <div className='split-box blue-theme'>
            <div className='left-box split-box-c50'>
              <div
                className='left-box-img'
                style={{
                  backgroundImage:
                    'url(' + links.getPublicURL('/img/engagement.jpg') + '',
                  height: '500px',
                  width: '100%'
                  // backgroundSize: 'cover'
                }}
              ></div>
            </div>
            <div className='right-box split-box-c50 right-box-ceremony'>
              <p className='content-heading blue-theme'>Wedding Ceremony</p>
              <a
                href='https://goo.gl/maps/GwFKjUL8SBRFLNPT8'
                target='_blank'
                className='content-sub-heading blue-theme'
                rel='noreferrer'
              >
                Transportation: Ferry (every 20 minutes) from Valkosaari Lautta.
                Starting at 13:00PM
              </a>
              <p className='content-sub-heading blue-theme'>
                Date: 31st July 2022
              </p>
              <p className='content-sub-heading blue-theme'>
                Times: Doors open 14:00, be seated for ceremony at latest 14:40.
                Dinner + games till 19:00
              </p>
              <p className='content-sub-heading blue-theme details'>
                Main ceremony where bride and groom will give their vows
                followed by dinner and speeches.
              </p>
            </div>
          </div>
          {/* After Party block */}
          <div className='split-box blue-theme'>
            <div className='left-box split-box-c50'>
              <div
                className='left-box-img'
                style={{
                  backgroundImage:
                    'url(' + links.getPublicURL('/img/party2.jpeg') + '',
                  height: '500px',
                  width: '100%'
                }}
              ></div>
            </div>
            <div className='right-box split-box-c50 right-box-party'>
              <p className='content-heading blue-theme'>THE Party</p>
              <a
                href='https://goo.gl/maps/GwFKjUL8SBRFLNPT8'
                target='_blank'
                className='content-sub-heading blue-theme'
                rel='noreferrer'
              >
                Departure Transportation: Ferry returns every 20 minutes back to
                Valkosaari Lautta (last ferry at 22:20)
              </a>
              <p className='content-sub-heading blue-theme'>
                Date: 31st July 2022
              </p>
              <p className='content-sub-heading blue-theme'>
                Times: DJ starts right after first dance at 19:00 and party ends
                at 22:00
              </p>
              <p className='content-sub-heading blue-theme details'>
                Party & games will end at 10 PM and also the last ferry.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Things to do in Helsinki block */}
      <div className='home-section'>
        <div className='container'>
          <p className='content-heading'>Things to do in Helsinki</p>
          <p className='section-subheading'>
            Many of you are traveling from your home county all the way here, so
            we wanted to make it easier & worth your travel visit here
          </p>
          <div className='split-box red-theme'>
            <div className='left-box'>
              <div
                className='left-box-img'
                style={{
                  backgroundImage:
                    'url(' + links.getPublicURL('/img/blueberry.jpeg') + '',
                  height: '200px',
                  width: '200px'
                }}
              ></div>
              <div
                className='left-box-img'
                style={{
                  backgroundImage:
                    'url(' + links.getPublicURL('/img/helsinki.jpg') + '',
                  height: '200px',
                  width: '400px'
                }}
              ></div>
              {/* left block two images togetger */}
              <div
                className='left-box-img'
                style={{
                  height: '600px',
                  width: '250px'
                }}
              >
                <div
                  className='left-box-img'
                  style={{
                    backgroundImage:
                      'url(' + links.getPublicURL('/img/kayak1.jpeg') + '',
                    height: '200px',
                    width: '250px'
                  }}
                ></div>
                <div
                  className='left-box-img'
                  style={{
                    backgroundImage:
                      'url(' + links.getPublicURL('/img/sauna.jpeg') + '',
                    height: '200px',
                    width: '250px'
                  }}
                ></div>
                <div
                  className='left-box-img'
                  style={{
                    backgroundImage:
                      'url(' + links.getPublicURL('/img/frisbee-golf.jpg') + '',
                    height: '200px',
                    width: '250px'
                  }}
                ></div>
              </div>

              <div
                className='left-box-img'
                style={{
                  backgroundImage:
                    'url(' + links.getPublicURL('/img/transport.jpg') + '',
                  height: '250px',
                  width: '300px'
                }}
              ></div>

              <div
                className='left-box-img'
                style={{
                  backgroundImage:
                    'url(' + links.getPublicURL('/img/nuksio.jpeg') + '',
                  height: '350px',
                  width: '350px'
                }}
              ></div>
            </div>
            <div className='right-box right-box-thingstodo'>
              <p className='content-heading helsinki-heading'>Things to do</p>
              <a
                href='https://www.getyourguide.com/helsinki-l13/from-helsinki-return-day-trip-ferry-ticket-to-tallinn-t205504/'
                target='_blank'
                className='content-sub-heading'
                rel='noreferrer'
              >
                Day trip with Ferry to Tallin, Estonia
              </a>
              <a
                href='https://www.nationalparks.fi/berryandmushroompicking'
                target='_blank'
                className='content-sub-heading'
                rel='noreferrer'
              >
                Berry & Mushroom Picking in the Forest
              </a>
              <a
                href='https://www.suomenlinna.fi/en/fortress/'
                target='_blank'
                className='content-sub-heading'
                rel='noreferrer'
              >
                Suomenlinna Fort
              </a>
              <a
                href='https://allasseapool.fi/?lang=en'
                target='_blank'
                className='content-sub-heading'
                rel='noreferrer'
              >
                ALLAS Sea Pool (Sauna/Sea Pools etc)
              </a>
              <a
                href='https://www.discgolfpark.com/disc-golf/case-finland/'
                target='_blank'
                className='content-sub-heading'
                rel='noreferrer'
              >
                Frisbee Golf
              </a>
              <a
                href='https://www.nationalparks.fi/nuuksionp'
                target='_blank'
                className='content-sub-heading'
                rel='noreferrer'
              >
                Nuuksio National Park
              </a>
              <a
                href='https://www.myhelsinki.fi/en/see-and-do/activities'
                target='_blank'
                className='content-sub-heading'
                rel='noreferrer'
              >
                Activities to do in Finland (useful link)
              </a>
              <a
                href='https://www.supregatta.com/'
                target='_blank'
                className='content-sub-heading'
                rel='noreferrer'
              >
                Kayaking in Helsinki
              </a>
              <a
                href='https://www.hsl.fi/en/tickets-and-fares'
                target='_blank'
                className='content-sub-heading'
                rel='noreferrer'
              >
                Helsinki Public Transport
              </a>
              <a
                href='https://www.hsl.fi/en/citybikes'
                target='_blank'
                className='content-sub-heading'
                rel='noreferrer'
              >
                Helsinki City Bikes
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Gifts block */}
      <div className='home-section'>
        <div className='container'>
          <p className='content-heading'>Gifts</p>
          <p className='section-subheading'>
            Firstly gifts are optional but also big thanks to anyone gifting! To
            make gifting easier we have a few prepared methods. If none of these
            work for you but still want to gift, ping either Dipika or Johann :)
          </p>
          <a
            href='https://www.amazon.de/wedding/johann-du-toit-dipika-baad-helsinki-july-2022/registry/25B1FLOCIX1W9'
            className='content-sub-heading'
          >
            Amazon Wedding Gift Registry
          </a>
          <p className='section-subheading'>
            MobilePay (service available only in Finland) can also be used with
            the amount you wish, number is: +358417171494
          </p>
        </div>
      </div>

      {/* Dress Code block */}
      <div className='home-section'>
        <div className='container'>
          <p className='content-heading'>Dress Code</p>
          <p className='section-subheading'>
            Mehendi Day - Casual Summer Clothing 👕👗, Formal if you wish &
            Indian 🥻 if you are feeling brave :)
          </p>
          <p className='section-subheading'>
            Wedding Day - Formal 🕴️👔👗 Wedding Party Wear & feel free to wear
            your own style/traditional clothing if you wish :)
          </p>
        </div>
      </div>

      <footer className='footer'>
        <p className='footer-text'>
          Made with <span className='fas fa-heart'></span> by Dipika & Johann
        </p>
      </footer>
    </div>
  )
}
