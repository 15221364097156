import CONSTANTS from './constants';
import browser from './utils/browser';
import env from './utils/env';

export const reducer = (state, action) => {
  switch (action.type) {
    case "locale":
      return {
        ...state,
        locale: action.locale
      }
    case "configure":
      return {
        ...state,
        user: action.user,
        token: action.token,
      }
    case "mobile-menu-open":
      return {
        ...state,
        showmobilemenu: true
      }
    case "mobile-menu-close":
      return {
        ...state,
        showmobilemenu: false
      }
    case "sidebar-expand":
      return {
        ...state,
        collapsed: false
      }
    case "sidebar-collapse":
      return {
        ...state,
        collapsed: true
      }
    case "search":
      return {
        ...state,
        search: action.search
      }
    case "selected":
      return {
        ...state,
        selected: action.selected
      }
    case "tick":
      return {
        ...state,
        time: new Date().getTime()
      }
    case "login":
      return {
        ...state,
        user: action.user,
        token: action.token,
      }
    case "logout":
      return {
        ...state,
        user: null,
        token: null,
      }

    default:
      return state
  }
}

export const initialState = {
  collapsed: localStorage.getItem(CONSTANTS.APP_PREFIX_KEY + '.sidebarcollapsed') === 'yes',
  search: browser.getQueryParam('q', ''),
  loaded: new Date().getTime(),
  showmobilemenu: false,
  build: env.get('REACT_APP_BUILD', null),
  selected: null,
  locale: localStorage.getItem(CONSTANTS.APP_PREFIX_KEY + '.locale') || 'en',
  user: null,
  token: localStorage.getItem(CONSTANTS.APP_PREFIX_KEY + '.token')
}