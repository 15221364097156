import React, { useContext, useEffect } from 'react'
import browser from '../../utils/browser'
import './style.scss'
import { Link } from 'react-router-dom'
import { AppContext } from '../../context'

// eslint-disable-next-line
export default () => {
  // eslint-disable-next-line
  const [appstate, appdispatch] = useContext(AppContext)
  useEffect(() => {
    // set the seo settings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className='notfound'>
      <div className='inner'>
        <p>notfound</p>
        <Link to='/'>Back to home</Link>
      </div>
    </div>
  )
}
