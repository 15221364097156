import env from './env';

const isProduction = env.isProduction();
const logging = {};

logging.debug = function (message) {
    if (isProduction) return;
    console.debug("DEBUG: ", message)
}

logging.warning = function (message) {
    console.warn("WARNING: ", message)
}

logging.info = function (message) {
    console.info("INFO: ", message)
}

logging.error = function (message, error_content = null) {
    console.error("ERROR: ", message, error_content)
}

export default logging;
