import React, { useContext, useEffect, useState, useRef } from 'react'
import browser from '../../utils/browser'
import http from '../../utils/http'
import links from '../../utils/links'
import './style.scss'
import { AppContext } from '../../context'
import { useNavigate } from 'react-router'
import { Link, useParams } from 'react-router-dom'

const DEFAULT_GUEST_DATA = {
  name: '',
  menu: '',
  age: '',
  restrictions: ''
}

// eslint-disable-next-line
export default () => {
  // eslint-disable-next-line
  const params = useParams()
  const [appstate, appdispatch] = useContext(AppContext)
  const navigate = useNavigate()
  const [item, setItem] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [section, setSection] = useState('loading')
  // const inputName = useRef()
  const inputEmail = useRef()
  const inputAttendance = useRef()
  const inputMehendi = useRef()
  const [guestRefs, setGuestRefs] = useState({})
  useEffect(() => {
    const configure = async function () {
      let response = await http.call({
        path: '/rsvp/' + params.uid,
        method: 'GET'
      })
      if (response.ok) {
        let newGuestRefs = {}
        for (let i = 0; i < response.data.max; i++) {
          newGuestRefs['name-' + i] = React.createRef()
          newGuestRefs['age-' + i] = React.createRef()
          newGuestRefs['menu-' + i] = React.createRef()
          newGuestRefs['restrictions-' + i] = React.createRef()
        }
        setGuestRefs(newGuestRefs)
        setItem(response.data)
        browser.setTitle('RSVP - ' + response.data.email)
        setSection('view')
      } else {
        navigate('/#rsvp')
        // setSection('error')
      }
    }
    configure()
    browser.setTitle('RSVP form')
  }, [])
  const getRefValue = key => {
    if (!guestRefs[key]) return
    if (!guestRefs[key].current) return
    return guestRefs[key].current.value || null
  }
  const handleSubmit = async e => {
    if (e) e.preventDefault()
    setSection('submitting')
    let guestlist = []
    for (let i = 0; i < item.max; i++) {
      guestlist.push({
        name: getRefValue('name-' + i),
        age: getRefValue('age-' + i),
        menu: getRefValue('menu-' + i),
        restrictions: getRefValue('restrictions-' + i)
      })
    }
    let response = await http.call({
      path: '/rsvp/' + item.uid,
      method: 'POST',
      form: {
        // name: inputName.current.value,
        email: inputEmail.current.value,
        attendance: inputAttendance.current.value,
        mehendi: inputMehendi.current.value,
        guests: JSON.stringify(guestlist)
      }
    })
    if (response.ok) {
      setSection('submitted')
    } else {
      setErrorMessage(response.data.message)
      setSection('view')
    }
  }
  if (section === 'submitting') {
    return (
      <div className='rsvp'>
        <div className='rsvp-message-wrapper'>
          <h2 className='rsvp-title'>One Moment....</h2>
          <p className='rsvp-message-message'>Saving your rsvp details...</p>
          <div className='clearfix'></div>
        </div>
      </div>
    )
  }
  if (section === 'submitted') {
    return (
      <div className='rsvp'>
        <div className='rsvp-message-wrapper'>
          <h2 className='rsvp-title'>Success!</h2>
          <p className='rsvp-message-message'>
            Saved RSVP successfully, can't wait to celebrate it with you all!{' '}
          </p>
          <div className='clearfix'></div>
          <Link className='rsvp-button' to='/'>
            Back to Homepage
          </Link>
        </div>
      </div>
    )
  }
  if (section === 'loading') {
    return <p>loading...</p>
  }
  if (section === 'error') {
    return <p>something went wrong...</p>
  }
  const renderGuestBlock = function (index, guestitem) {
    return (
      <div key={'guestblock' + index} className='guestblock'>
        <p>
          Guest #{index + 1}{' '}
          <span
            style={{
              fontSize: 13
            }}
          >
            (just leave name blank if they are no longer attending)
          </span>
          :
        </p>
        <div className='columns'>
          <div className='column column-small'>
            <input
              placeholder='Guest name'
              type='text'
              ref={guestRefs['name-' + index]}
              defaultValue={guestitem.name || ''}
            />
          </div>
          <div className='column column-small'>
            <select
              ref={guestRefs['age-' + index]}
              defaultValue={guestitem.age}
            >
              <option value='adult'>Adult</option>
              <option value='child'>Child (4 - 12 years)</option>
              <option value='toddler'>Toddler (below 4 years)</option>
            </select>
          </div>
          <div className='column column-small'>
            <select
              ref={guestRefs['menu-' + index]}
              defaultValue={guestitem.menu}
            >
              <option value='veg'>Vegetarian</option>
              <option value='vegan'>Vegan</option>
              <option value='non-veg'>Non-Vegetarian</option>
            </select>
          </div>
          <div className='column column-small'>
            <input
              placeholder='Dietary restrictions?'
              type='text'
              ref={guestRefs['restrictions-' + index]}
              defaultValue={guestitem.restrictions || ''}
            />
          </div>
          <div className='clearfix'></div>
        </div>
      </div>
    )
  }
  const renderGuestBlocks = function (refs) {
    const guestitems = item.guests || []
    const els = []
    const max = item.max || 0
    for (let i = 0; i < max; i++) {
      els.push(renderGuestBlock(i, guestitems[i] || DEFAULT_GUEST_DATA, refs))
    }
    return <div>{els}</div>
  }
  return (
    <div className='rsvp'>
      <div className='rsvp-box'>
        <div className='rsvp-wrapper'>
          <img
            src={links.getPublicURL('/img/logo.png')}
            alt='Logo for the site that says "Getting Married"'
            className='rsvp-bg'
          />
          {errorMessage ? <p style={{ color: 'red' }}>{errorMessage}</p> : null}
          <form onSubmit={handleSubmit}>
            <div className='meta'>
              <h2>RSVP Details</h2>
              <p>
                So happy to have you at our big day! Please fill in the details
                below on your attendance + guests who will be joining. You can
                come back and edit this information till about a month before
                the event when we need final numbers.
              </p>

              <p>Contact E-Mail (for event details+updates):</p>
              <input ref={inputEmail} type='text' defaultValue={item.email} />

              <p>
                How are you attending? For those attending virtually we will
                send out link to the livestream the morning of 31st July 2022
                for you to join in!
              </p>
              <select ref={inputAttendance} defaultValue={item.attendance}>
                <option value='no'>Not attending</option>
                <option value='in-person'>
                  I want to attend in Person in Finland
                </option>
                <option value='virtual'>I want to attend virtually</option>
              </select>

              <p>
                Are you attending{' '}
                <a
                  href='https://en.wikipedia.org/wiki/Mehndi'
                  target='_blank'
                  rel='noreferrer'
                >
                  Mehendi
                </a>{' '}
                Day? This will be on the Friday 29th of July 2022, we will have
                a mehendi artist and Indian+South African snacks for everyone to
                try and just party a bit. The Mehendi needs a day or two to dry
                before the actual event on Sunday. Should be no more than 3-4
                hours the Friday evening and will take place at a venue
                announced closer to the date.
              </p>
              <select
                ref={inputMehendi}
                defaultValue={item.mehendi === true ? 'yes' : 'no'}
              >
                <option value='no'>No we cannot make the Mehendi Day</option>
                <option value='yes'>Yes we will join the Mehendi Day</option>
              </select>
            </div>

            <h2>Guest Details:</h2>
            <p>
              This RSVP allows {item.max} guests (including yourself) as indicated in your
              pre-invite, if you need more please contact the bride and groom!
            </p>

            {renderGuestBlocks()}
            <button className='rsvp-button' onClick={handleSubmit}>
              Save Changes
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
