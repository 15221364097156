import links from './links'
const axios = require('axios')

var http = {}

/**
 * Do the actual call using the client API
 */
http.call = async function (params) {
  // get the method for call
  let method = (params.method || params.type || 'get').toLowerCase()

  // build up the headers
  let headers = params.headers || {}

  // set the query
  let query = params.query || {}

  // set the url
  let url = params.url || links.getAPIPath(params.path)

  // get the count
  let count = Object.keys(query || {}).length

  // set it
  if (count > 0) {
    url = url + links.getQuery(query)
  }

  // get the params
  let options = {
    timeout: 1000 * (params.timeout || 40),
    headers: headers,
    method: method,
    url: url
  }

  // set it
  if (method === 'post') options.data = params.form

  // Send a POST request
  try {
    let response = await axios(options)
    return {
      data: response.data || {},
      ok: response.status >= 200 && response.status < 300,
      status: response.status,
      headers: response.headers
    }
  } catch (err) {
    let response = err.response
    if (response) {
      return {
        data: response.data || {},
        ok: false,
        status: response.status,
        headers: response.headers
      }
    }
    throw err
  }
}

// set as default
export default http
