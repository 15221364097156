
import env from './env';

const isProduction = env.isProduction();
const overrideAPIBaseURL = env.get('REACT_APP_API_BASE');
const overrideWEBBaseURL = env.get('REACT_APP_WEB_BASE');

const links = {};

links.getBaseName = function() {
  return isProduction ? '/wedding' : ''
}

links.getPublicURL = function(path) {
  return links.getBaseName() + path
}

links.getWebBase = function () {

  if(overrideWEBBaseURL) return overrideWEBBaseURL;

  if (isProduction) {
    return 'https://egpaar.com';
  } else {
    return 'http://localhost:3000';
  }

};

links.getAPIBase = function () {

  if(overrideAPIBaseURL) return overrideAPIBaseURL;

  if (isProduction) {
    return 'https://api.egpaar.com';
  } else {
    return 'http://localhost:8181';
  }

};

links.getPreviewBase = function () {

  return 'https://preview.egpaar.com';

};

links.getCDNBase = function () {

  return 'https://cdn.egpaar.com';

};

links.getUploadBase = function () {

  return 'https://uploads.egpaar.com';

};

links.getQuery = function (params) {
  if (!params) return '';
  if (Object.keys(params).length <= 0) return '';
  let searchParams = new URLSearchParams(params);
  return searchParams.toString();
}

links.getPreviewPath = function (path, query) {

  return links.getPreviewBase() + path + links.getQuery(query);

}

links.getCDNPath = function (path, query) {

  return links.getCDNBase() + path + links.getQuery(query);

}

links.getWebPath = function (path, query) {

  return links.getWebBase() + path + links.getQuery(query);

}

links.getAPIPath = function (path, query) {

  return links.getAPIBase() + path + links.getQuery(query);

}

links.getUploadPath = function (path, query) {

  return links.getUploadBase() + path + links.getQuery(query);

}

export default links;