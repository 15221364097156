var env = {};

env.isProduction = function (opts) {
  return env.get('NODE_ENV', 'development') === 'production';
}

env.get = function(key, defaultVal=null) {
  try {
    if(!process.env[key]) return defaultVal;
    return process.env[key];
  } catch(err) {
    console.error(err);
    return defaultVal;
  }
};

// set as default
export default env;