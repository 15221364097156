import { Helmet } from "react-helmet";
const browser = {};

/**
 * 
 */
browser.setQueryParams = function (givenParams) {
  const loc = window.location;
  if (!loc) return;
  const params = new URLSearchParams(loc.search);
  for (let key in givenParams) {
    params.set(key, givenParams[key]);
  }
  if (!window.history) return;
  const textQuery = params.toString();
  if (textQuery === '') return;
  window.history.replaceState({}, '', `${loc.pathname}?${textQuery}`);
};

/**
 * 
 */
browser.getQueryParams = function () {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
};

/**
 * 
 */
browser.getQueryParam = function (key, defaultValue=null) {
  const params = browser.getQueryParams();
  return params[key] || defaultValue;
};

/**
 * 
 */
browser.setTitle = function (text) {
  let newTitle = 'D&J Wedding';
  if (text) {
    newTitle = text + ' | D&J Wedding';
  }
  document.title = newTitle;
};

/**
 * 
 */
browser.setDescription = function (text) {
  document.querySelector('meta[name="description"]').setAttribute("content", text);
}

/**
 * 
 */
browser.setKeyWords = function (keywords) {
  document.querySelector('meta[name="keywords"]').setAttribute("content", keywords.join(', '));
}

/**
 * 
 */
browser.renderJSONLD = function (data) {
  const structuredJSON = JSON.stringify(data)
  return (<Helmet>
    <script type="application/ld+json">{structuredJSON}</script>
  </Helmet>)
}

export default browser;